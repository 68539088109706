import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { ThemeProvider } from 'styled-components'

import Header from '../components/Header'
import Footer from '../components/Footer'
import '../all.scss'
import { LazyMotion, domAnimation } from 'framer-motion'

const theme: DefaultTheme = {
  brand: '#f79312',
  text: '#202020',
  grey: '#f5f5f5',
}

const Main = styled.main`
  flex-grow: 1;
`

export default function Layout({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  return (
    <LazyMotion features={domAnimation}>
      <ThemeProvider theme={theme}>
        <>
          <Header />
          <Main>{children}</Main>
          <Footer />
        </>
      </ThemeProvider>
    </LazyMotion>
  )
}
