import React, { FC } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Base = styled.footer`
  text-align: center;
  padding: 1em;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.brand};
  font-family: Jaapokki, Tahoma, sans-serif;
  .link {
    font-size: 1.175em;
    padding: 0.5em;
    text-decoration: none;
    color: inherit;
  }
  .links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 440px;
    margin: 1em auto;
}
  }
  p {
    margin: 0.5em 0;
    font-size: 0.875em;
  }
`

const Footer: FC = () => (
  <Base>
    <div className="links">
      <Link className="link" to="/privacy-policy">
        Privacy Policy
      </Link>
      {/* <span>|</span>
      <Link className="link" to="/terms-conditions">
        Terms & Conditions
      </Link>
      <span>|</span>
      <Link className="link" to="/site-map">
        Site Map
      </Link> */}
    </div>
    <p>
      ©Copyright {new Date().getFullYear()} Doomsday Tuna - All rights reserved
    </p>
  </Base>
)

export default Footer
