import React, { FC } from 'react'
// import posed from 'react-pose'

// const lineTransition = {
//   duration: 150,
//   y: { delay: 0 },
//   rotate: { delay: 150 },
// }
// const Line1 = posed.line({
//   close: {
//     y: 0,
//     rotate: 0,
//     transition: lineTransition,
//   },
//   open: {
//     y: 40,
//     rotate: 45,
//     transition: lineTransition,
//   },
// })
// const Line2 = posed.line({
//   close: {
//     scaleX: 1,
//   },
//   open: {
//     scaleX: 0.01,
//     transition: {
//       default: { delay: 150 },
//     },
//   },
// })
// const Line3 = posed.line({
//   close: {
//     y: 0,
//     rotate: 0,
//     transition: lineTransition,
//   },
//   open: {
//     y: -40,
//     rotate: -45,
//     transition: lineTransition,
//   },
// })
const BurgerMenu: FC<{
  onClick: React.MouseEventHandler<SVGSVGElement>
  open: boolean
}> = ({ onClick, open }) => (
  <svg
    className="burger-menu"
    width="48"
    height="48"
    viewBox="0 0 100 100"
    onClick={onClick}>
    <line
      // pose={open ? 'open' : 'close'}
      stroke="#202020"
      strokeWidth="8"
      strokeLinecap="round"
      x1="10"
      x2="90"
      y1="10"
      y2="10"
    />
    <line
      // pose={open ? 'open' : 'close'}
      stroke="#202020"
      strokeWidth="8"
      strokeLinecap="round"
      x1="10"
      x2="90"
      y1="50"
      y2="50"
    />
    <line
      // pose={open ? 'open' : 'close'}
      stroke="#202020"
      strokeWidth="8"
      strokeLinecap="round"
      x1="10"
      x2="90"
      y1="90"
      y2="90"
    />
  </svg>
)

export default BurgerMenu
