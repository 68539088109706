import React, { useState } from 'react'
import styled from 'styled-components'
import { Match } from '@reach/router'
import { Link } from 'gatsby'
import TextScroller from './TextScroller'
import BurgerMenu from './BurgerMenu'
import logo from '../img/logo.svg'

const Bar = styled.span`
  ::before {
    content: '|';
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  font-family: Jaapokki, Tahoma, sans-serif;
  color: ${({ theme }) => theme.text};

  .link {
    font-size: 1.25em;
    padding: 0.5em;
    text-decoration: none;
    color: inherit;
  }

  .logo {
    display: flex;
    justify-content: center;
    height: calc(80px - 2em);
    img {
      margin: 0 1em;
      height: 100%;
    }
  }
`

const Base = styled.header`
  overflow: hidden;
  flex-shrink: 0;
  transition: max-height 300ms;
  &.closed {
    max-height: 72px;
  }
  &.open {
    max-height: 320px;
  }
  .burger-menu {
    display: none;
    position: absolute;
    top: 1em;
    right: 1em;
  }
  @media (max-width: 720px) {
    nav {
      flex-direction: column;
      height: auto;
    }
    .logo {
      order: 1;
      align-self: flex-start;
      margin: 0;
    }
    .link {
      order: 2;
    }
    .burger-menu {
      display: block;
    }
    ${Bar} {
      display: none;
    }
  }
`

export default function Header(): JSX.Element {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Base className={open ? 'open' : 'closed'}>
        <BurgerMenu onClick={() => setOpen((o) => !o)} open={open} />
        <Nav
          onClick={(e) => {
            if (
              !(e.target instanceof HTMLElement) ||
              e.target.nodeName !== 'A'
            ) {
              return
            }
            setOpen(false)
            // wait for close animation to finish
            setTimeout(() => {
              document.querySelector('main')?.scrollIntoView(true)
            }, 200)
          }}>
          <Link className="link" to="/blog">
            The Blog
          </Link>
          <Bar />
          <Link className="link" to="/about">
            About
          </Link>
          {/* <Bar />
          <Link className="link" to="/projects">
            Our Work
          </Link> */}
          <Link to="/" className="logo">
            <img src={logo} alt="Doomsday Tuna Logo" width="100" height="48" />
          </Link>
          <Link className="link" to="/services">
            Services
          </Link>
          <Bar />
          <Link className="link" to="/contact#contact-form">
            Contact
          </Link>
        </Nav>
      </Base>
      <Match path="/blog/:slug/">
        {({ match }) =>
          match ? null : ( // don't render for blog posts
            <TextScroller
              // fade
              texts={[
                <h1 className="hero">
                  Because regular tuna
                  <br />
                  can’t make websites
                </h1>,
                <h1 className="hero">
                  Because your website
                  <br />
                  is worth it
                </h1>,
                <h1 className="hero">
                  Because you deserve
                  <br />a website that works
                </h1>,
                <h1 className="hero">
                  Because your business
                  <br />
                  deserves the world
                </h1>,
              ]}
              interval={7500}
            />
          )
        }
      </Match>
    </>
  )
}
