import React, { ReactNode, useEffect, useState, Component } from 'react'
import styled from 'styled-components'
import background from '../img/textscroller-background.webp'
import { m, AnimatePresence } from 'framer-motion'
import { ReactComponentLike } from 'prop-types'

const DefaultBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${background}) no-repeat center/cover;
  position: relative;
  overflow: hidden;
  padding: 4em 0;
  @media (max-width: 724px) {
    min-height: 385px;
    padding: 3em 0;
  }
  @media (max-width: 624px) {
    min-height: 410px;
    font-size: 0.9em;
  }
  @media (max-width: 410px) {
    min-height: 450px;
    font-size: 0.85em;
    padding: 2em 0;
  }
  @media (max-width: 320px) {
    min-height: 480px;
    font-size: 0.8em;
  }
  .hero {
    margin: 0;
  }
`

export default function TextScroller({
  texts,
  interval = 1000,
  base: Base = DefaultBase,
}: {
  texts: ReactNode[]
  interval?: number
  base?: React.ComponentType<{ children: ReactNode }>
}) {
  const [index, setIndex] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => (index + 1 >= texts.length ? 0 : index + 1)),
      interval,
    )
    return () => clearInterval(intervalId)
  }, [interval, texts])
  return (
    <Base>
      <AnimatePresence>
        <m.div
          key={index}
          initial={{
            y: '-100%',
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            position: 'absolute',
            y: '100%',
            opacity: 0,
          }}>
          {texts[index]}
        </m.div>
      </AnimatePresence>
    </Base>
  )
}
